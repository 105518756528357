export let device = {
    wechat:isWeChatBrowser(),
    mobile:navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) ? true : false,
}

function isWeChatBrowser(){
    let ua = navigator.userAgent.toLowerCase();
    return /micromessenger/.test(ua) ? true : false;
}

