import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import Vant from 'vant'
import tools from '@/assets/api/tools'
import '@/assets/css/reset.scss'
import 'element-ui/lib/theme-chalk/index.css';
import 'vant/lib/index.css'
import '@/assets/css/pc.style.scss'

import Router from 'vue-router'
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.config.productionTip = false
Vue.use(ElementUI,{size:'small'});
Vue.use(Vant)
Vue.prototype.setMessage = tools.setMessage

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
