import Vue from 'vue'
import vuex from 'vuex'
import main from './modules/main'

import getters from './getters'

Vue.use(vuex)

const store = new vuex.Store({
  modules: {
    main
  },
  getters
})

export default store
