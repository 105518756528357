import axios from 'axios'
import {device} from './device'
import {Notify} from 'vant';
import { Message } from 'element-ui';
/**
 * 时间戳转化为年 月 日 时 分 秒
 * number: 传入时间戳
 * format：返回格式，支持自定义，但参数必须与formateArr里保持一致
 * formatTime(date,'Y-M-D h:m:s')
 */
function formatTime(number,format) {
  let formateArr  = ['Y','M','D','h','m','s'];
  let returnArr   = [];
  let date = new Date(number);
  returnArr.push(date.getFullYear());
  returnArr.push(formatNumber(date.getMonth() + 1));
  returnArr.push(formatNumber(date.getDate()));
  returnArr.push(formatNumber(date.getHours()));
  returnArr.push(formatNumber(date.getMinutes()));
  returnArr.push(formatNumber(date.getSeconds()));
  for (let i in returnArr) {
    format = format.replace(formateArr[i], returnArr[i]);
  }
  return format;
}
//数据转化
function formatNumber(n) {
  n = n.toString()
  return n[1] ? n : '0' + n
}

function downLoadFile (url) {
  const link = document.createElement('a')
  link.href = url + '?' + new Date().getTime()
  document.body.append(link)
  link.click()
  document.body.removeChild(link)
}

function setMessage(type = 'success',message){
  if(device.mobile){
    Notify({type: type, message: message});
  }else{
    Message({type: type, message: message})
  }
}

function getAuthentication(data){
  let a = ['avatarApprove','cardApprove','educationApprove','certificateApprove','wxCodeApprove']
  let t = '0'   // 0 未审核 1审核已通过 2审核未通过 3全部审核通过
  if(data[a[0]] === '0'
    && data[a[1]] === '0'
    && data[a[2]] === '0'
    && data[a[3]] === '0'
    && data[a[4]] === '0'){
    t = '0'
  }
  if(data[a[0]] === '1'
    || data[a[1]] === '1'
    || data[a[2]] === '1'
    || data[a[3]] === '1'
    || data[a[4]] === '1'){
    t = '1'
  }
  if(data[a[0]] === '2'
    && data[a[1]] === '2'
    && data[a[2]] === '2'
    && data[a[3]] === '2'
    && data[a[4]] === '2'){
    t = '2'
  }
  if(data[a[0]] === '1'
    && data[a[1]] === '1'
    && data[a[2]] === '1'
    && data[a[3]] === '1'
    && data[a[4]] === '1'){
    t = '3'
  }
  return t
}

export default {
  formatTime,
  downLoadFile,
  setMessage,
  getAuthentication
}