import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home')
  }, {
    path: '/oxman',
    name: '牛人',
    component: () => import('@/views/oxman')
  }, {
    path: '/news',
    name: '消息中心',
    component: () => import('@/views/news')
  }, {
    path: '/user',
    name: '个人中心',
    component: () => import('@/views/user')
  }, {
    path: '/home-screen',
    name: '首页筛选',
    component: () => import('@/views/home-screen')
  }, {
    path: '/oxman-screen',
    name: '牛人筛选',
    component: () => import('@/views/oxman-screen')
  }, {
    path: '/search-oxman-page',
    name: '牛人筛选',
    component: () => import('@/views/search-oxman-page')
  }, {
    path: '/detail',
    name: '详情',
    component: () => import('@/views/detail')
  }, {
    path: '/custom-services',
    name: '定制服务',
    component: () => import('@/views/custom-services')
  }, {
    path: '/oxman-detail',
    name: '定制服务',
    component: () => import('@/views/oxman-detail')
  }, {
    path: '/news-feedback',
    name: '反馈消息',
    component: () => import('@/views/news-feedback')
  }, {
    path: '/news-distribution',
    name: '分销消息',
    component: () => import('@/views/news-distribution')
  }, {
    path: '/news-system',
    name: '系统消息',
    component: () => import('@/views/news-system')
  }, {
    path: '/oxman-topping',
    name: '牛人置顶',
    component: () => import('@/views/oxman-topping')
  }, {
    path: '/active-vip',
    name: '开通会员',
    component: () => import('@/views/active-vip')
  }, {
    path: '/user-info',
    name: '用户中心',
    component: () => import('@/views/user-info')
  }, {
    path: '/user-order',
    name: '我的订单',
    component: () => import('@/views/user-order')
  }, {
    path: '/user-collect',
    name: '我的收藏',
    component: () => import('@/views/user-collect')
  }, {
    path: '/user-wallet',
    name: '我的钱包',
    component: () => import('@/views/user-wallet')
  }, {
    path: '/user-cash',
    name: '提现',
    component: () => import('@/views/user-cash')
  }, {
    path: '/user-add-cash',
    name: '添加提现方式',
    component: () => import('@/views/user-add-cash')
  }, {
    path: '/user-like',
    name: '牛人关注列表',
    component: () => import('@/views/user-like')
  }, {
    path: '/feedback',
    name: '意见反馈',
    component: () => import('@/views/feedback')
  }, {
    path: '/official-account',
    name: '关注公众号',
    component: () => import('@/views/official-account')
  }, {
    path: '/order-detail',
    name: '订单详情',
    component: () => import('@/views/order-detail')
  }, {
    path: '/user-share',
    name: '分享获佣',
    component: () => import('@/views/user-share')
  }, {
    path: '/recent-income',
    name: '最近收入',
    component: () => import('@/views/recent-income')
  }, {
    path: '/user-coupon',
    name: '我的卡券',
    component: () => import('@/views/user-coupon')
  }, {
    path: '/user-setting',
    name: '设置',
    component: () => import('@/views/user-setting')
  }, {
    path: '/login',
    name: '验证码登录',
    component: () => import('@/views/login')
  }, {
    path: '/login-password',
    name: '密码登录',
    component: () => import('@/views/login-password')
  },{
    path:'/identity-selection',
    name:'身份选择',
    component: () => import('@/views/identity-selection')
  }, {
    path: '/register',
    name: '注册',
    component: () => import('@/views/register')
  }, {
    path: '/retrieve-password',
    name: '找回密码',
    component: () => import('@/views/retrieve-password')
  }, {
    path: '/change-password',
    name: '修改密码',
    component: () => import('@/views/change-password')
  }, {
    path: '/change-phone',
    name: '修改手机号',
    component: () => import('@/views/change-phone')
  }, {
    path: '/input-code',
    name: '输入验证码',
    component: () => import('@/views/input-code')
  }, {
    path: '/binding-new-phone',
    name: '绑定新手机号',
    component: () => import('@/views/binding-new-phone')
  }, {
    path: '/base-info',
    name: '基础信息填写',
    component: () => import('@/views/base-info')
  }, {
    path: '/auth-info',
    name: '认证信息',
    component: () => import('@/views/auth-info')
  }, {
    path: '/auth-info-real',
    name: '认证信息',
    component: () => import('@/views/auth-info-real')
  }, {
    path: '/writing-list',
    name: '历年真题',
    component: () => import('@/views/writing-list')
  }, {
    path: '/special-list',
    name: '专项训练',
    component: () => import('@/views/special-list')
  }, {
    path: '/search-page',
    name: '试卷筛选',
    component: () => import('@/views/search-page')
  }, {
    path: '/web-detail',
    name: '详情',
    component: () => import('@/views/web-detail')
  }, {
    path: '/web-oxman',
    name: '牛人列表',
    component: () => import('@/views/web-oxman')
  }, {
    path: '/web-oxman-detail',
    name: '牛人详情',
    component: () => import('@/views/web-oxman-detail')
  }, {
    path: '/web-user-info',
    name: '用户中心',
    component: () => import('@/views/web-user-info'),
    children: [
      {
        path:'',
        name:'账号信息',
        component: () => import('@/views/web-user-info/pages/account-info')
      },{
        path: 'web-vip',
        name: '会员中心',
        component: () => import('@/views/web-user-info/pages/web-vip')
      },{
        path: 'web-wallet',
        name: '我的钱包',
        component: () => import('@/views/web-user-info/pages/web-wallet')
      },{
        path: 'web-order',
        name: '我的订单',
        component: () => import('@/views/web-user-info/pages/web-order')
      },{
        path: 'web-collect',
        name: '我的收藏',
        component: () => import('@/views/web-user-info/pages/web-collect')
      },{
        path: 'web-coupon',
        name: '下载卡券',
        component: () => import('@/views/web-user-info/pages/web-coupon')
      },{
        path: 'web-share-obtain',
        name: '分享获佣',
        component: () => import('@/views/web-user-info/pages/web-share-obtain')
      },{
        path: 'web-like',
        name: '牛人关注',
        component: () => import('@/views/web-user-info/pages/web-like')
      },{
        path: 'web-online',
        name: '牛人上线',
        component: () => import('@/views/web-user-info/pages/web-online')
      },{
        path: 'web-news',
        name: '我的消息',
        component: () => import('@/views/web-user-info/pages/web-news')
      },{
        path: 'web-wx-code',
        name: '关注公众号',
        component: () => import('@/views/web-user-info/pages/web-wx-code')
      }
    ]
  },{
    path:'/web-view-agree',
    name:'用户协议',
    component:()=>import('@/views/web-view-agree')
  }, {
    path: '/test',
    name: '测试页面',
    component: () => import('@/views/test')
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//     // 增加登录页
//     let token = localStorage.getItem('token')
//     if(!token
//         && to.path !== '/login'
//         && to.path !== '/register'
//         && to.path !== '/login-password'
//         && to.path !== '/retrieve-password'
//     ){
//       next('/login')
//     }else{
//       next()
//     }
//     return;
// })

export default router
