import {device} from "@/assets/api/device";
const state = {
    DEV_IS_MOBILE:device.mobile,
    DEV_IS_WECHAT:device.wechat,
    DEV_WXCHART:{},
    DEV_PAPER_HEIGHT:'calc(100vw - 60px)',
    DEV_MESSAGE:{}
}

const mutations = {
    setMobileValue:(state,val)=>{
        state.DEV_IS_MOBILE = val
    },
    setPaperHeight:(state,val)=>{
        state.DEV_PAPER_HEIGHT = val
    },
    setMessage:(state,val)=>{
        state.DEV_MESSAGE = val
    },
}

export default {
    namespaced: true,
    state,
    mutations
}